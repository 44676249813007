import React from 'react';
import { Typography,Card,CardHeader,CardContent,Stack,Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ConfettiExplosion from 'react-confetti-explosion';

export function Certification(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    return (
        <>
        {isHovered && <ConfettiExplosion onComplete={()=>setIsHovered(false)}/>}
        <div onMouseOver={(e)=>setIsHovered(true)}>
            <Card sx ={{border:"none",boxShadow:"none"}}>
                <CardHeader
                avatar={
                    <Avatar src={props.logo}></Avatar>
                  }
                  title={props.name} 
                  subheader={props.issued}/>  
                <CardContent>
                <Typography variant="h6" component="div">   {props.position} </Typography>
                    <Stack sx={{marginLeft:"30px"}} direction={"col"} gap={1}>
                        <Link to={props.link} target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon />
                        </Link>

                        <Typography variant="body2" color="text.secondary">
                            View Certificate
                        </Typography>
                    </Stack>

                </CardContent>
            </Card>
        </div>
        </>
    );
}
