import React from 'react';
import { Typography,Avatar,Stack,Card,CardContent,CardHeader,Chip } from '@mui/material';


export function EducationCard(props) {
    return (
        <div>
            <Card variant="outlined" sx={{border:"none",boxShadow:"none"}}>
                <CardHeader
                    avatar={
                        <Avatar src={props.logo} sizes='large' ></Avatar>
                    }
                    title={props.school} 
                    subheader={props.location}/>  

                <CardContent>
                    <Typography sx={{ mb: 1.5 }}  fontWeight={"bold"}>
                    {props.degree}
                    </Typography>
                    <Stack direction="row"   sx={{ flexWrap: 'wrap', gap: 1 }}>
                    <Typography variant="body2" color="text.secondary">Relevant Courses:</Typography>
                    {(Object.keys(props.courses).map((item) => {
                            return <Chip label={props.courses[item]}></Chip>
                        }))}
                    </Stack>
                    <Typography variant="h6" color="text.secondary">
                        {props.graduation}
                    </Typography>
                    
                </CardContent>
            </Card>

        </div>
    );
}
