import React from 'react';
import bg3 from '../assets/bg5.png';
import {Grid,Box,Typography} from '@mui/material';

export function SecondaryHeader(){
    return(
        <>
        <Grid container spacing={2} sx={{backgroundColor:"#fcfdfc"}} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={6} >
                <img src={bg3} alt="bg3" style={{width:'100%',height:'50vh',objectFit:'contain'}}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box padding = {3} backgroundColor="#fcfdfc">
                    <Typography variant="h6">
                    As a senior computer science student, I'm driven by innovation and problem-solving. With expertise in programming, algorithms, and software development, I'm adaptable and eager to learn. I excel in teamwork and clear communication, making complex concepts accessible. I'm passionate about leveraging technology to address real-world challenges and create positive change. 
                    </Typography>
                    <br></br>
                    <Typography variant='h6'>
                    Let's connect to collaborate on driving technological advancements together.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        </>
    )
}