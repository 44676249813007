import React from 'react';
import GitHubIcon from '@mui/icons-material/GitHub';
import { IconButton,Stack,Box} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export function WebsiteLinks(props) {
    return (
        <div>
            <Box display="flex"
                justifyContent="center"
                alignItems="center" marginBottom={5}>
            <Stack direction="row" gap={1} >
                <IconButton href="https://github.com/miraj2399" target='_blank'>
                    <GitHubIcon fontSize='large'/>
                </IconButton>
                <IconButton href='https://www.linkedin.com/in/mirajmhossain/' target='_blank'>
                    <LinkedInIcon fontSize='large'/>
                </IconButton>
            </Stack>
            </Box>
        </div>
    );
}

export default WebsiteLinks;