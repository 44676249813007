import React from 'react';
import { AppBar,Toolbar,Typography,Button,Box,Stack} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import { Link } from 'react-router-dom';

export function Navbar(props) {
    
    return (
        <div>
           <AppBar position="static" color='transparent' elevation={0} >
                <Toolbar>
                <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                    <CodeRoundedIcon/>
                    <Typography variant="body1">Miraj Hossain</Typography>
                </Stack>
                <Button variant="standard" size="large" component = {Link} to={'/'} activeClassName="highlighted" >
                    About
                </Button>

                <Button variant="standard" size="large"  component = {Link} to={'/resume'} activeClassName="highlighted">
                    Resume
                </Button>

                <Button variant="standard" size="large" component = {Link} to={'/projects'} activeClassName="highlighted" >
                    Projects
                </Button>

                </Toolbar>
            </AppBar> 
        </div>
    );
}

export default Navbar;