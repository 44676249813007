import React from 'react';
import { MainHeader } from '../components/MainHeader';
import Navbar from '../components/Navbar';
import { WebsiteLinks } from '../components/WebsiteLinks';
import { SecondaryHeader } from '../components/SecondaryHeader';
import { Box,Grid } from '@mui/material';

export function About(props) {
    return (
    <Grid >
        <Grid item xs={12}>
            <Navbar/>
        </Grid>
        <Grid item xs={12}>
            <MainHeader />
        </Grid>
        <Grid item xs={12}>
            <SecondaryHeader/>
        </Grid>
        <Grid item xs={12}>
            <img src= "quote1.png" alt="quote1" style={{width:"100%",height:"auto"}}/>
        </Grid>
        <Grid item xs={12}>
            <Box marginTop={5}>
                <WebsiteLinks/>
            </Box>
        </Grid>
        

    </Grid> 
    );
}
