import React from 'react';
import { Typography,Paper, Divider, Grid,Box,Button} from '@mui/material';
import { Stack } from '@mui/system';
import bg1 from '../assets/bg2.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


export function MainHeader(props) {
    return (
        <div>
            <Grid container spacing={2} sx={{backgroundColor:"#fcfdfc"}}>
                <Grid item xs={12} md={6}>
            <Box elevation={0} sx={{  backgroundColor:"#fcfdfd",height:'80vh',width:'100%',display:'flex',alignItems:'center',justifyContent:'center' }} padding={3}>
                <Stack>
                <Typography variant="h3" color="black" sx={{fontFamily:'Roboto',fontWeight:'bold',textTransform:'uppercase',letterSpacing:'0.1rem'}}>
                   I'm Miraj Hossain
                </Typography>
                <Typography variant="h5" color="black" sx={{fontFamily:'Helvetica Neue',textTransform:'uppercase',letterSpacing:'0.1rem'}} gutterBottom>
                    Majoring in CS at Rutgers University
                </Typography>
                <Button variant='text' size="large" color="success" width="40%" href= "https://www.linkedin.com/in/mirajmhossain/" target='_blank' endIcon={<LinkedInIcon/>}> Connect </Button>
                </Stack>
            </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src={bg1} alt="bg1" style={{width:'100%',height:'80vh',objectFit:'contain'}} fetchpriority="high" />
            </Grid>
            </Grid>
        </div>
    );
}

