import { Card,CardMedia,Typography} from '@mui/material';
import { padding } from '@mui/system';
import React from 'react';

export function FrameWork(props) {
    return (
        <div>
            <Card sx = {{
                minWidth: "6rem", border:"none",boxShadow:"none", 
            }}>
                <CardMedia component="img" image={props.logo} alt={props.name} sx={{ height:"3rem",objectFit: "contain", padding: "1em 1em 1em 1em"}}/>
                <Typography variant="body2"  textAlign={"center"}>{props.name}</Typography>
            </Card>
        </div>
    );
}
