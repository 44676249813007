import React from 'react';
import {Card,Typography,CardContent,CardActions,Button,Box,Stack,SvgIcon, CardHeader, Avatar, CssBaseline} from '@mui/material';
import { EducationCard } from '../components/EducationCard';
import WorkIcon from '@mui/icons-material/Work';
import { ExperienceCard } from '../components/ExperienceCard';
import { SkillsCard } from '../components/SkillsCard';
import SchoolIcon from '@mui/icons-material/School';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Navbar from '../components/Navbar';
import { Certification} from '../components/Certification';
export function Resume(props) {
    let data = {
        'education': [
            {
                'school': 'Rutgers University',
                'location': 'New Brunswick, NJ',
                'degree': 'B.S. in Computer Science',
                'graduation': 'Expected graduation in December 2023',
                'logo': 'rutgers.png',
                'courses': {
                    '1': 'Data Structures',
                    '2': 'Algorithm Analysis',
                    '3': 'Intro to Data Management',
                    '4': 'Principal of Software Engineering',
                    '5': 'Principles of Programming Languages',
                    '6': 'Internet Technologies',
                    '7': 'Intro to Data Science',
                }
            },
            {
                'school': 'Union College',
                'location': 'Cranford, NJ',
                'degree': 'Associates in Computer Science & Engineering',
                'graduation': 'Graduated in August 2021',
                'logo': 'ucc.jpeg',
                'courses': {
                    '1': 'Intro to Operating Systems',
                    '2': 'Computer Programming Fundamentals',
                }
                    
            },],
            'experience': [
                {
                    'logo': 'ups.png',
                    'company': 'UPS',
                    'location': 'Mahway, NJ',
                    'position': 'DevOps Engineering Intern',
                    'duration': 'May 2023 - Current',
                    'description': {
                    '1': "Developed a PowerShell script to automate the updating of service hooks in Azure DevOps, ensuring seamless integration with Jenkins even when passwords were changed.",
                    '2': "Conducted a thorough evaluation of various low-code and no-code platforms to identify the most suitable options for the company's needs, providing insights on their features, capabilities, and potential integration with existing systems.",
                    '3': "Designed and implemented a Jenkins pipeline, streamlining the build, test, and deployment processes for software projects, resulting in increased efficiency and reduced time-to-market.",
                    '4': "Collaborated with team members to identify areas of improvement in the company's DevOps practices, actively participating in meetings and discussions to contribute ideas and solutions for enhancing the overall development and deployment workflows.",
                    '5': "Assisted in performing essential administrative tasks related to the DevOps environment, such as maintaining documentation, organizing team resources, and coordinating with other teams to ensure smooth project execution.",
                    '6': "Demonstrated strong problem-solving skills and attention to detail when troubleshooting issues or identifying potential bottlenecks in the DevOps pipeline, actively seeking solutions and proposing improvements to optimize the workflow.",
                    '7': "Actively engaged in professional development opportunities, staying updated with the latest trends and advancements in DevOps practices, attending relevant workshops, and pursuing additional certifications to enhance skills and contribute to the team's success."
                    }
                    },
                {
                    'logo': 'ucc.jpeg',
                    'company': 'Union College',
                    'location': 'Cranford, NJ',
                    'position': 'Peer Tutor',
                    'duration': 'January 2021 - July 2021',
                    'description': {
                        '1': "Provided one-to-one or small group tutoring on Algebra, Pre-calculus I, and Calculus II to improve the student’s skills in these subjects",
                        '2': "Performed essential administrative duties when needed. Kept log of student appointment duration and provided to instructors upon request",
                        '3': "Involved and participated in joint meetings of student representatives, instructors, and administrative bodies to improve college offerings for academic success"
                    }
                },
                {
                    'logo': 'ucc.jpeg',
                    'company': 'Union College',
                    'location': 'Cranford, NJ',
                    'position': 'Research Assistant',
                    'duration': 'May 2021 - August 2021',
                    'description': {
                        '1': "Led the electrical team to build a prototype of a life-saving aquatic drone with remote and semi-autonomous capability. The drone could avoid collision using radar and image processing technology and operate through radio communication",
                        '2': "Organized meetings with the mechanical team to get updates on design choices. These meetings helped to coordinate the mechanical and electrical aspects of the drone",
                    }
                },
                
            ],
            'skills': [ 
                { 'name': 'Python', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
              'frameworks': [
                    { 'name': 'Django', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" },
                    { 'name': 'FastAPI', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/fastapi/fastapi-original.svg" },
                    { 'name': 'Pandas', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pandas/pandas-original.svg' },
                    { 'name': 'Numpy', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/numpy/numpy-original.svg' },
                    { 'name': 'Matplotlib', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/matlab/matlab-original.svg" },
                    { 'name': 'Socket', 'logo':   'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/socketio/socketio-original.svg' },
                    ]},
        { 'name': 'JavaScript', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" , 'frameworks': [
            { 'name':"HTML", 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" },
            { 'name': 'CSS', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg" },
            { 'name': 'Node.js', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg" },
            { 'name': 'React', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
            { 'name': 'Material UI', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg' },
            { 'name': 'Express', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg' },
            ]},
        { 'name': 'Java' , 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg", 'frameworks': [
            { 'name': 'Spring Boot', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg' },
            { 'name': 'JavaFX', 'logo':  'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-plain.svg' },
            ]},
        { 'name': 'Developer tools', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg", 'frameworks': [
            { 'name': 'Git', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg' },
            { 'name': 'Docker', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg' },
            { 'name': 'Vim', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vim/vim-original.svg" },
            { 'name': 'Android Studio', 'logo':  'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/androidstudio/androidstudio-original.svg' },
            { 'name':"Jenkins", 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg' },
            { 'name': 'Ansible', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ansible/ansible-original.svg' },
            { 'name': "PowerShell", 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg' },
            ]},
        { 'name': 'Databases', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-plain.svg", 'frameworks': [
            { 'name': 'MySQL', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg"},
            { 'name': 'MongoDB', 'logo':  'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg'},
            { 'name': 'PostgreSQL', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg' },
            ]},
        { 'name': 'Cloud (AWS)', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg", 'frameworks': [
            { 'name': "EC2", 'logo': 'EC2.png' },
            {  'name': "Lambda", 'logo': 'Lambda.png' },
            { 'name': "S3", 'logo': 'S3.png' },
            { 'name': "RDS", 'logo': 'RDS.png' },
            { 'name': "CloudWatch", "logo": 'CloudShell.png' },
            { 'name': "Cognito", 'logo': 'Cognito.png' },
        ]},
    ],
    'certifications': [
        {
            'name': 'AWS Certified Solutions Architect - Associate',
            'logo': 'aws-sa.png',
            'issued': 'September 10, 2023',
            'link': "https://www.credly.com/badges/68e768ee-8c2c-4fe7-8b0a-4e1134d1ae5f/public_url",
        },
    ]

    }
    return (
        
        <Box sx={{marginLeft: '1rem', maxWidth: '100%', minHeight: '100vh'}}>
            <CssBaseline/>

            <Navbar></Navbar>
           <Stack direction="row" alignItems="center" gap={1} minHeight={"8rem"} >
                   <SchoolIcon/>
                    <Typography variant="h5">Education</Typography>
            </Stack>
            {(data.education.map((item) => {
                return <EducationCard {...item}/>
            }))}

            <Stack direction="row" alignItems="center" gap={1} minHeight={"8rem"}>
                     <WorkIcon/>
                    <Typography variant="h5">Experience</Typography>
            </Stack>
            {(data.experience.map((item) => {
                return <ExperienceCard {...item}/>
            }))}

            <Stack direction="row" alignItems="center" gap={1} minHeight={"8rem"}>
                        <Diversity2Icon/>
                        <Typography variant="h5">Certifications</Typography>
            </Stack>
            {(data.certifications.map((item) => {
                return <Certification {...item}/>
            }))}
            
            <Stack direction="row" alignItems="center" gap={1} minHeight={"8rem"}>
                        <Diversity2Icon/>
                        <Typography variant="h5">Skills</Typography>
            </Stack>
            {(data.skills.map((item) => {
                return <SkillsCard {...item}/>
            }))}
            
            
        </Box>
    );
}