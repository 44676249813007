
// MUI components

// pages

import { Route,Link,Routes,BrowserRouter } from 'react-router-dom';
import {About} from './pages/About';
import { Projects } from './pages/Projects';
import { Resume } from './pages/Resume';
import {Navbar} from './components/Navbar';
import { Paper,Button,Typography,Stack,Toolbar,AppBar,Card,CardHeader,Avatar, CssBaseline} from '@mui/material';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';

function App() {
  
  return (
    <div >
    <CssBaseline/>
    

      <BrowserRouter>
        <Routes>
        <Route  path="/" element={<About/>}/>
        <Route path="/resume" element={<Resume/>}/>
        <Route path="/projects" element={<Projects/>}/>
      </Routes>
      </BrowserRouter>


    </div> 
    
  );
}

export default App;
