import React from 'react';
import Navbar from '../components/Navbar';
import { ProjectCard } from '../components/ProjectCard';
import { Box,Grid} from '@mui/material';
import { ProjectCardV2 } from '../components/ProjectCardV2';

let data = [
    {
        title: "Empotex",
        logo: "empotex-logo.png",
        github: 'https://github.com/miraj2399/employee-management-3',
        subheader: "Crud Application to manage employee data",
        images: [
            "empotex1.png",
            "empotex2.png",
            "empotex3.png",
            "empotex4.png",
            "empotex5.png",
            "empotex6.png",
            "empotex7.png"
        ],
        tabs: [
            {
                title: "Description",
                content: [
                    { 'type':"Break"},
                    {
                        "type": "Typography",
                        "content": "Developed a MERN (MongoDB, Express.js, React.js, Node.js) application to automate the calculation of employee clock-in and clock-out times, eliminating the need for manual calculations.",
                        "color": "text.primary",
                        "variant": "body1"
                        },
                        { 'type':"Break"},
                        {
                        "type": "Typography",
                        "content": "Implemented a user-friendly interface that allows employees to easily record their clock-in and clock-out times, ensuring accurate time tracking.",
                        "color": "text.primary",
                        "variant": "body1"
                        },
                        { 'type':"Break"},
                        {
                        "type": "Typography",
                        "content": "Utilized the MERN stack to create a seamless and efficient system for managing employee attendance without the need for manual calculations.",
                        "color": "text.primary",
                        "variant": "body1"
                        },
                        { 'type':"Break"},
                        {
                        "type": "Typography",
                        "content": "Designed an intuitive dashboard that provides real-time visibility into employee clock-in and clock-out times, reducing administrative workload and potential errors.",
                        "color": "text.primary",
                        "variant": "body1"
                        },
                        { 'type':"Break"},
                        {
                        "type": "Typography",
                        "content": "Streamlined the process of tracking employee work hours, improving efficiency and accuracy in calculating wages and labor costs.",
                        "color": "text.primary",
                        "variant": "body1"
                        }
                        
                ],
                includeFramework: true,
            },
    
            {
                title: "Why I made this",
                content: [
                    
                    { "type": "Break" },
                    {
                        "type": "Typography",
                        "content": "I developed this MERN app to automate employee clock-in/out and streamline labor cost analysis for my family member's small business. By leveraging my skills, I eliminated manual calculations, provided a user-friendly interface, and enabled real-time visibility into employee hours and revenue."
                    }
                    ],
                includeFramework: false,
            },
            {
                title:"API Documentation",
                content:[
                    { "type": "Break" },
                    {"type":"Typography","content":"Visit","color":"text.secondary","variant":"body1"},
                    {"type":"Link","href":"https://documenter.getpostman.com/view/20101626/2s93sjTTvP"},
                    {"type":"Break"},
                    { "type": "Break" },
                    {"type":"Image","src":"postman-api.png","alt":"empotex-api"},
                    { "type": "Break" },
                ],
            },
            {
                title: "Future Plans",
                content: [
                    { "type": "Break" },
                    { "type": "Typography", "content": "This project is still under development. I have plan to test it and deploy it on cloud ", "color": "text.secondary", "variant": "body1" },
                    { "type": "Break" },
                    { "type": "Typography", "content": "I also plan to add a task section with the ability to assign and manage task for individual employee", "color": "text.secondary", "variant": "body1" },
                    { "type": "Break" },
                    { "type": "Typography", "content": "Further I want to implement the admin and moderator permission system and the ability for the admins to set custom permission sets for moderators", "color": "text.secondary", "variant": "body1" },
                    { "type": "Break" },
                ]
            }
        ],
        

        frameworks: [
            { 'name': 'React', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
            { 'name': 'MongoDB', 'logo':  'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg'},
            { 'name': 'Express', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg' },
            { 'name': 'Node', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg' },
            { 'name': 'Material UI', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg' },
            ],
    },
    {
        title:"Photos Libary",
        github: "https://github.com/miraj2399/PhotoLibrary/",
        logo: "photos-logo.png",
        subheader: "Desktop application to manage photos",
        images: [
            "photos1.png",
            "photos2.png",
            "photos3.png",
            "photos4.png",
            "photos5.png",
            "photos6.png",
        ],
        tabs: [
            {
                title: "Description",
                content: [
                    { 'type':"Break"},
                    {
                    "type": "Typography",
                    "content": "Developed a JavaFX photo library application with user authentication, allowing users to securely login and logout.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    {'type':"Break"},
                    {
                    "type": "Typography",
                    "content": "Implemented features to add albums, tags, and photos, providing users with the ability to organize their photo collection efficiently.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    {'type':"Break"},
                    {
                    "type": "Typography",
                    "content": "Incorporated advanced search functionality to enable users to search for photos based on tags or album names, facilitating quick and convenient photo retrieval.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    {'type':"Break"},
                    {
                    "type": "Typography",
                    "content": "Designed a user-friendly interface with intuitive navigation and visually appealing layouts, enhancing the overall user experience.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    {'type':"Break"},
                    {
                    "type": "Typography",
                    "content": "Ensured data security and privacy by implementing secure authentication mechanisms and protecting sensitive user information.",
                    "color": "text.primary",
                    "variant": "body1"
                    }
                    ],
                    includeFramework: true,
                }
            ],
            frameworks: [
                { 'name': 'Java', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg' },
                { 'name': 'JavaFX', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg' },
            ],

        
                

    },

    {
        title: "BlueQuiz",
        github: 'https://github.com/miraj2399/BlueQuiz',
        subheader: "Quiz app to test funny trivia knowledge",
        logo: "bluequiz-logo.png",
        images: [
            "bluequiz1.png",
            "bluequiz2.png",
            "bluequiz3.png",
        ],
        tabs: [
            {
                title: "Description",
                content: [
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Developed a user-friendly quiz app using Django, HTML, and CSS.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Implemented user authentication to allow secure login and access to personalized quiz content.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Designed an intuitive and responsive UI/UX for easy navigation and seamless quiz experience.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Developed a database schema to store quiz questions, options, and user responses for accurate evaluation and result tracking.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Implemented quiz submission and result display functionalities to provide instant feedback and assessment for users.",
                    "color": "text.primary",
                    "variant": "body1"
                    }
                    ],
                includeFramework: true,
                }
        ],
        description: [
            { 'type': 'Typography', 'content': 'This is a header', 'color': 'text.secondary', 'variant': 'h5' },
            { 'type': 'Typography', 'content': 'This is a paragraph', 'color': 'text.secondary', 'variant': 'body1' },
            { 'type': 'Break' },
            {'type': 'Typography', 'content': 'This is a paragraph', 'color': 'text.secondary', 'variant': 'body1' },
        ],
        frameworks: [
            { 'name': 'Django', 'logo': "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg" },
            {"name":"HTML", "logo":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"},
            {"name":"CSS", "logo":"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"},
            ],
    },
    {
        title: "mirajh.com",
        logo:"personal.png",
        subheader: "Personal website to showcase my projects",
        images: [
            "personal.png",
        ],
        tabs: [
            {
                title: "Description",
                content: [
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Developed a personal website using React.js, leveraging the powerful capabilities of the framework to create an interactive and responsive user interface.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Utilized the Material-UI (MUI) library to implement modern and visually appealing design components, enhancing the overall aesthetics and user experience of the website.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Hosted the website on AWS CloudFront and S3, leveraging the scalability, reliability, and global distribution capabilities of the AWS infrastructure.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Implemented efficient caching and content delivery strategies to optimize website performance and ensure fast loading times for visitors across different geographical locations.",
                    "color": "text.primary",
                    "variant": "body1"
                    },
                    { "type": "Break" },
                    {
                    "type": "Typography",
                    "content": "Ensured data security and privacy by following best practices, such as configuring appropriate access controls and encryption mechanisms for the website and its assets.",
                    "color": "text.primary",
                    "variant": "body1"
                    }
                    ],
                includeFramework: true,
            },
            {
                title: "Cloud Technologies",
                content: [
                    { "type": "Break" },
                    {"type":"Image", "src": "aws-personal-website.png", "alt": "design"}
                ]
            },
            
        ],
        frameworks: [
            { 'name': 'React.js', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
            { 'name': 'Material-UI', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg' },
            { 'name': 'AWS', 'logo': 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg' },
        ],
    }
]
export function Projects(props) {
    return (
        <>
            <Navbar/>
                {data.map((item) => {
                    return  <ProjectCard {...item}/>
                })}
                
        </>
    );

}

