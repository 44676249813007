import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import bg1 from '../assets/bg1.png';
import bg2 from '../assets/bg2.png';
import bg3 from '../assets/bg3.png';
import { Box } from '@mui/material';

export function Carousel (props)  {
    const images = props.images;
    return(
        <Box padding={3}>
    <AwesomeSlider buttons={false}
    media={
        images.map((image)=>({
            source:image
        }))
    }
    />

</Box>

    )
}
