import { CardMedia,CardContent,Card,CardHeader,Typography,Stack,IconButton, Grid} from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import { FrameWork } from './FrameWork';
import React from 'react';
import { Tab,Tabs,Avatar,Link} from '@mui/material'
import { Carousel} from './Carousel';

function JsonToHtml(json) {
    return json.map((item) => {
        if (item.type === 'Typography') {
            return <Typography variant={item.variant} color={item.color}>{item.content}</Typography>
        }
        else if (item.type === 'Break') {
            return <br></br>
        }
        else if (item.type==="Image"){
            return <img src={item.src} alt={item.alt} objectFit="contain" width="100%" height="100%"></img>
        }
       else if (item.type==="Link"){
            return <a href={item.href} target="_blank">{item.href}</a>
       }
    })
}


const json = [
    {
        type:'Typography',
        content:'This is a header',
        color:'text.secondary',
        variant:'h1'

    },
    {
        type:'Typography',
        content:'This is a paragraph',
        color:'text.secondary',
        variant:'body1'
    },
    {
        type:'Break'
    },


]



export function ProjectCard(props) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        
        <div>
            <Card sx ={{width:"100%", border:"none",boxShadow:"none"}}>
                <CardHeader  avatar={
          props.logo ? (
            <Avatar src={props.logo} aria-label="recipe">
              {props.logo}
            </Avatar>
          ) : null
        }action={<div>
              <IconButton aria-label="settings" href={props.github} target="_blank">
                <GitHubIcon  fontSize='large'/>
              </IconButton></div>
          } title={
            <Typography gutterBottom variant="h4" component="h2">
               {props.title}
            </Typography>
         }  subheader= {
            <Typography variant="subtitle2" >
                {props.subheader}
            </Typography>
         }
            />
          <Grid container padding={3} >
                <Grid item xs={12} md={6} >
                <Carousel images={props.images}/>
                </Grid>
                <Grid item xs={12} md={6}>
                <CardContent>
                        <div >
                            <Tabs value={value} onChange={handleChange}>
                                {props.tabs.map((tab, index) => (
                                <Tab key={index} label={tab.title} />
                                ))}
                            </Tabs>
                            {props.tabs.map((tab, index) => (
                                <div key={index}>
                                {value === index && JsonToHtml(tab.content) }
                                { value === index && tab.includeFramework && <Stack direction={"row"}>
                                            {props.frameworks.map((item) => {
                                            return <FrameWork {...item}></FrameWork>})}
                        </Stack>}
                                </div>
                            ))}
                        </div>
                </CardContent>
                </Grid>
                </Grid>
            </Card>
        </div>
    );
}
