import React from 'react';
import { Typography,Card,CardHeader,CardContent,Stack,Avatar, List, ListItem, ListItemAvatar,Box } from '@mui/material';
import { FrameWork } from './FrameWork';
export function SkillsCard(props) {
    return (
        <div>
            <Card sx={{border:"none",boxShadow:"none"}}>
                
                <CardContent>
                    <Stack direction={'row'} sx={{ flexWrap: 'wrap', gap: 1 }}>
                    <Stack direction={'column'} sx={{ flexWrap: 'wrap', gap: 1 }} width={"7rem"} >
                        <Avatar src={props.logo}></Avatar>
                        <Typography variant="body2" component="div" gutterBottom>   {props.name} </Typography>
                    </Stack>
                        <Stack>
                           
                            <Stack direction={'row'}  sx={{ flexWrap: 'wrap', gap: 1 }}>
                            {(Object.keys(props.frameworks).map((item) => {
                            return <FrameWork {...props.frameworks[item]}></FrameWork>
                        }))}

                            </Stack>
                    </Stack>
                    </Stack>
                </CardContent>

            </Card>
        </div>
    );
}
