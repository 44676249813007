import React from 'react';
import { Typography,Card,CardHeader,CardContent,Stack,Avatar, List, ListItem } from '@mui/material';

export function ExperienceCard(props) {
    return (
        <div>
            <Card sx ={{border:"none",boxShadow:"none"}}>
                <CardHeader
                avatar={
                    <Avatar src={props.logo}></Avatar>
                  }
                  title={props.company} 
                  subheader={props.duration}/>  
                <CardContent>
                <Typography variant="h6" component="div">   {props.position} </Typography>
                    <List>
                        {(Object.keys(props.description).map((item) => {
                            return <ListItem>{props.description[item]}</ListItem>
                        }))}
                    </List>
                </CardContent>
            </Card>
        </div>
    );
}
